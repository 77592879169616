//*---LIKE, SHARE, SHARE IMAGE*----//
import React, { useState } from "react";
import { view } from "@risingstack/react-easy-state";
import { storage } from "react-easy-params";
import toast from "react-hot-toast";
import firestore from "./../utils/firebase";
import firebase from "firebase/app";

import Spacer from "react-spacer";
import { Icon } from "@iconify/react";

const ThingsInfo = (data, thingSetId) => {
  const [faceState, setFaceState] = useState(
    storage.user &&
      data.thing.likedBy &&
      data.thing.likedBy.includes(storage.user.uid)
      ? "CLICKED"
      : "DEFAULT"
  );

  const seeMoreHandler = (data) => {
    let baseUrl = "localhost:3000";

    if (process.env.NODE_ENV === "production") {
      baseUrl = "https://www.fivethingsthataregood.com";
    }
    navigator.clipboard.writeText(`${baseUrl}/thingsets/${data.thing.id}`);

    toast.success("Link copied!", {
      duration: 2000,
      position: "bottom-center",
      className: "Toast",
      iconTheme: { primary: "#24272F", secondary: "#61DB86" },
    });
  };

  const thing = data.thing;
  thing.id = data.thingSetId;

  thing.isAuthor = false;

  if (storage.user && storage.user.uid === thing.user.id) {
    thing.isAuthor = true;
  }

  const handleFavorites = async (id, likes) => {
    if (storage.user && storage.user.isAnonymous !== false) {
      return false;
    }

    let add = true;

    if (data.thing.likedBy && data.thing.likedBy.includes(storage.user.uid)) {
      add = false;
    }

    const newUpvoteCount = add ? likes + 1 : likes - 1;

    try {
      await firestore
        .collection("five_things")
        .doc(`${id}`)
        .update({
          likes: newUpvoteCount,
          likedBy: add
            ? firebase.firestore.FieldValue.arrayUnion(storage.user.uid)
            : firebase.firestore.FieldValue.arrayRemove(storage.user.uid),
        });
    } catch (e) {
      console.log(e);
    } finally {
      setFaceState(add ? "CLICKED" : "DEFAULT");
    }
  };

  return (
    <>
      <div className="thingsInfoWrapper">
        <div className="createdInfo">
          <div>
            By{" "}
            {thing.user.isAnonymous ? (
              <span>anonymous</span>
            ) : (
              <span>
                <a href={`user/` + thing.user.id}>{thing.user.name}</a>.
              </span>
            )}
          </div>
        </div>

        <div className="IconsWrapper">
          {storage.user && storage.user.isAnonymous === false ? (
            <div
              className="faceCountWrapper"
              onClick={() => handleFavorites(thing.id, thing.likes)}
            >
              {(() => {
                switch (faceState) {
                  case "DEFAULT":
                    return (
                      <Icon
                        icon="akar-icons:face-happy"
                        alt="This is OK."
                        inline={true}
                        width="28"
                        height="28"
                      />
                    );
                  case "CLICKED":
                    return (
                      <Icon
                        icon="akar-icons:face-very-happy"
                        inline={true}
                        width="28"
                        height="28"
                        alt="Saved!"
                      />
                    );

                  default:
                    return (
                      <Icon
                        icon="akar-icons:face-happy"
                        alt="This is OK."
                        inline={true}
                        width="28"
                        height="28"
                      />
                    );
                }
              })()}

              <Spacer width="4px" />
              {thing.likes}
            </div>
          ) : null}
          <Spacer width="12px" />
          <div onClick={() => seeMoreHandler(data)}>
            <Icon
              icon="akar-icons:link-chain"
              inline={true}
              width="28"
              height="28"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default view(ThingsInfo);
