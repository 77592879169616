//In src/components/themeToggler.js
import React, { useContext } from 'react';
import { ThemeContext } from "./../ThemeContext.js";
import { Icon } from "@iconify/react";
import Spacer from "react-spacer";
import { storage } from "react-easy-params";

const ThemeToggler = (e) => {

  const {theme, setTheme} = useContext(ThemeContext);
  setTheme(theme);
  storage.theme = theme;

     return(

<div className="ThemeSwitcher">

        <button onClick={(e) => {setTheme('dark')}}>
            <span role="img" aria-label="switch theme to dark">
            <Icon
              icon="akar-icons:moon"
              alt="Enter dark mode"
              inline={true}
              width="24"
              height="24"
              className={theme === 'dark' ? "iconsSelected" : "icons"}
            />
            </span>
        </button>
        <Spacer width="16px"/>
        <button onClick={(e) => {setTheme('light')}}>
            <span role="img" aria-label="switch theme to light">
            <Icon
              icon="akar-icons:sun"
              alt="Enter light mode"
              inline={true}
              width="24"
              height="24"
              className={theme === 'light' ? "iconsSelected" : "icons"}
            />
            </span>
        </button>
        <Spacer width="16px"/>
        <button onClick={(e) => {setTheme('bright')}}>
            <span role="img" aria-label="switch theme to bright">
            <Icon
              icon="akar-icons:face-very-happy"
              alt="Enter bright mode"
              inline={true}
              width="24"
              height="24"
              className={theme === 'bright' ? "iconsSelected" : "icons"}
            />
            </span>
        </button>
        </div>


    )
}

export default ThemeToggler;
