import React from "react";
import Spacer from "react-spacer";
import { storage } from "react-easy-params";
import { view } from "@risingstack/react-easy-state";
import appStore from "./../../utils/appStore";
import { useMediaQuery } from "react-responsive";
import { Icon } from "@iconify/react";
import "./../../styles/app.css";
import "./../../styles/nav.css";

import { HashLink as Link } from "react-router-hash-link";
import Kofi from "./../../components/Kofi.js";


import ForgetMeModal from "./ForgetMeModal";

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

const LoggedIn = () => {
  return (
    <>
      <Default>

          <button
            className="logoutButton"
            onClick={() => appStore.signout()}
          >{`Log Out`}</button>
        <div className="BuyMeForgetMe">
          <Kofi id="fivethings" />
          <ForgetMeModal />

        </div>
      </Default>
      <Mobile>
        <div>
          <Spacer height="32px" />
          <button
            className="logoutButtonMobile"
            onClick={() => appStore.signout()}
          >{`Log Out`}</button>
        </div>
        <div className="BuyMeForgetMeMobile">

          <Kofi id="fivethings" />

          <ForgetMeModal />

        </div>
      </Mobile>
    </>
  );
};

const Anonymous = () => {
  return (
    <>
      <Default>
      <div><div className="aboveAuth">
            Post anonymously, or access <Link to="/about#featuresTable">more features</Link> with a FREE account
          </div>
          <div className="authButtonWrapper">
          <button className="authButton" onClick={() => appStore.appleSignin()}>
            <Icon
              icon="bx:bxl-apple"
              alt="Continue with Apple"
              inline={true}
              width="24"
              height="24"
              className="icons"
            />
            <Spacer width="8px" />
            {`Continue with Apple`}
          </button>
          <button className="authButton" onClick={() => appStore.googleAuth()}>
            <Icon
              icon="akar-icons:google-contained-fill"
              alt="Continue with Google"
              inline={true}
              width="24"
              height="24"
              className="icons"
            />
            <Spacer width="8px" />
            {`Continue with Google`}
          </button>
          <Kofi id="fivethings" />

        </div>
      </div>
      </Default>
      <Mobile>
        <div>
          <div class="aboveAuth">
          Post anonymously, or access <Link to="/about#featuresTable">more features</Link> with a FREE account
          </div>

<div className="authButtonWrapper">
          <button className="authButton" onClick={() => appStore.googleAuth()}>
            <Icon
              icon="ant-design:google-circle-filled"
              alt="Sign in with Google"
              inline={true}
              width="24"
              height="24"
              className="icons"
            />
            <Spacer width="8px" />
            {`Continue with Google`}
          </button>

          <button className="authButton" onClick={() => appStore.appleSignin()}>
            <Icon
              icon="bx:bxl-apple"
              alt="Sign in with Apple"
              inline={true}
              width="24"
              height="24"
              className="icons"
            />
            <Spacer width="8px" />
            {`Continue with Apple`}
          </button>
          <Kofi id="fivethings" />

          </div>

        </div>
      </Mobile>

    </>
  );
};

const AuthContainer = () => {
  const isAnonymous = storage.user && storage.user.isAnonymous !== false;
  return isAnonymous ? <Anonymous /> : <LoggedIn />;
};

export default view(AuthContainer);
