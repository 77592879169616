import React from "react";
import Popup from "reactjs-popup";
import toast from "react-hot-toast";
import "./../../styles/modal.css";
import "./../../styles/app.css";
import Spacer from "react-spacer";
import "reactjs-popup/dist/index.css";
import firebase from "firebase/app";
import appStore from "./../../utils/appStore";
import { storage } from "react-easy-params";

const forgetMe = async data => {
  try {
    // await firestore.collection("five_things").add(data);
    // Delete all things made by this person.
    const thingSets = await firebase.firestore()
    .collection("five_things")
    .where("user.id", "==", storage.user.uid)
      .get();

    const batch = firebase.firestore().batch();

    thingSets.forEach(doc => {
      batch.delete(doc.ref);
    });

    await batch.commit();

    toast.success("Oh. We'll miss you!");
    appStore.signout();
  } catch (e) {
    toast.error("Something went wrong...");
    console.log(e);
  } finally {

    return true;
  }
};

const ForgetMeModal = () => {
  return (
    <Popup
      trigger={<button style={{fontSize:'.5em', fontWeight:'400'}}> Forget my account. </button>}
      modal
      nested
    >
      {close => (
        <div className="modal" id="forgetMe">
          <div className="header">Do you really want us to forget your account? </div>
          <div className="content">
            {`Clicking "forget my account" below will delete your data (including favorites and posts) forever. Sign up again if you want a fresh start.`}
          </div>
          <div className="actions">
            <button
              onClick={() => {
                close();
              }}
            >
              Nevermind!
            </button>
            <Spacer width="32px"/>
            <button
                onClick={() => {
                  forgetMe();
                  close();
                }}
              >
            Delete all my data.
              </button>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default ForgetMeModal;
