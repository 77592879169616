import { store } from '@risingstack/react-easy-state';
import firebase from "firebase/app";
import "firebase/auth";
import { storage } from 'react-easy-params'



const appStore = store({
  appleSignin: async () => {
    try {
      const provider = new firebase.auth.OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      await firebase.auth().signInWithPopup(provider);
      const userCredentials = await firebase.auth().currentUser;

      storage.user = {
        isAuthenticated: true,
        isAnonymous: userCredentials.isAnonymous,
        uid: userCredentials.uid,
        name: userCredentials.displayName ? userCredentials.displayName.split(" ")[0] : "Fancy Apple User"
      }
    } catch (e) {
      console.log(e)
    }
    console.log(storage.user)
    return storage.user

  },
  anonymousAuth: async () => {
    try {
      await firebase.auth().signInAnonymously()
      const userCredentials = await firebase.auth().currentUser;
      storage.user = {
        isAuthenticated: true,
        isAnonymous: true,
        uid: userCredentials.uid,
        name: 'anonymous'
      }
    } catch (e) {
      console.log(e)
    }
    return storage.user
  },
  googleAuth: async () => {
    try {

      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({ prompt: 'select_account' });
      await firebase.auth().signInWithPopup(provider);
      const userCredentials = await firebase.auth().currentUser;

      storage.user = {
        isAuthenticated: true,
        isAnonymous: false,
        uid: userCredentials.uid,
        name: userCredentials.displayName.split(" ")[0]
      }

      return storage.user
    } catch (e) {
      console.log(e)
    }
  },
  facebookAuth: async () => {
    try {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.setCustomParameters({
        'display': 'popup'
      });

      await firebase.auth().signInWithPopup(provider)
      const userCredentials = await firebase.auth().currentUser;

      storage.user = {
        isAuthenticated: true,
        isAnonymous: userCredentials.isAnonymous,
        uid: userCredentials.uid,
        name: userCredentials.displayName.split(" ")[0]
      }

      return storage.user
    } catch (e) {
      console.log(e)
    }
  },
  signout: () => {
    firebase.auth().signOut()
    storage.user = {
      isAuthenticated: false,
      isAnonymous: true,
      name: 'anonymous',
      id: null
    }
  }
});

export default appStore;
