import React from "react";
import { Helmet } from "react-helmet";
import { view } from "@risingstack/react-easy-state";


const Header = (props) => {
return (
<Helmet>
<title></title>
<meta name="description" content="" />
<meta property="og:type" content="website" />
<meta name="og:title" property="og:title" content="Five Things That Are Good" />
<meta name="og:description" property="og:description" content="" />
<meta property="og:site_name" content="" />
<meta property="og:url" content="" />
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="" />
<meta name="twitter:description" content={props.desc} />
<meta name="twitter:site" content="" />
<meta name="twitter:creator" content="" />
<link rel="icon" type="image/png" href="/favicon.png" />
<link rel="apple-touch-icon" href="/favicon.png" />
<link rel="stylesheet" href="" />
<meta property="og:image" content="/favicon.png" />
<meta name="twitter:image" content="/favicon.png" />
<link rel="canonical" href="" />
<script type="text/javascript" src="" ></script>
</Helmet>
)};
export default view(Header);
