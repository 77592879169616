
import React from "react";

import "./../styles/kofi.css";
import "./../styles/index.css";


export default function KoFi(props) {
  const { id } = props;
  return (
    <div className="btn-container">
      <a
        className="kofi-button"
        href={"https://ko-fi.com/" + id}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="kofitext">
          <img
            src="https://ko-fi.com/img/cup-border.png"
            className="kofiimg"
            alt="Ko-Fi button"
          />
        Buy me a coffee
        </span>
      </a>
    </div>
  );
}
