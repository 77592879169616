import React from "react";
import FormFields from "./../components/FormFields.js";
import ThingsInfo from "./../components/ThingsInfo.js";
import PostData from "./../components/PostData.js";
import { storage } from "react-easy-params";
import { view } from "@risingstack/react-easy-state";
import "./../styles/index.css";
import "./../styles/output.css";


const ThingsContainer = (data) => {
  let isLiked = false;
  const thing = data.thingSet;
  thing.id = data.thingSetId;

  if (storage.user && storage.user.isAnonymous === false) {
    isLiked =
    thing.likedBy &&
    thing.likedBy.includes(storage.user.uid)
  }

  const cardClassName = isLiked ? "ThingsCardLiked" : "ThingsCard";
  const containerClassName = isLiked ? "ThingsContainerLiked" : "ThingsContainer";
  const formfieldsClassName = isLiked ? "FormFieldsLiked" : "FormFields";


  return (
    <div className={ data.isBright ? "ThingsCardLikedNoBG" : cardClassName }>
      <div className={containerClassName}>
      <div className={ data.isBright ? "FormFieldsLikedNoBG" : formfieldsClassName }>
        <FormFields thing={thing} />
      </div>
      <PostData thing={thing} />
      <ThingsInfo thing={thing} thingSetId={data.thingSetId} />
      </div>
    </div>

  );
};

export default view(ThingsContainer);
