//*---DELETE, fLAG----//
import React from "react";
import { view } from "@risingstack/react-easy-state";
import { storage } from "react-easy-params";
import toast from "react-hot-toast";
import dateParser from "./../utils/dateParser";
import firestore from "./../utils/firebase";
import firebase from "firebase/app";

const PostData = (data) => {
  const thing = data.thing;

  thing.isAuthor = false;

  let isLiked = false;
  thing.id = data.thingSetId;

  if (storage.user && storage.user.isAnonymous === false) {
    isLiked = thing.likedBy && thing.likedBy.includes(storage.user.uid);
  }

  if (storage.user && storage.user.uid === thing.user.id) {
    thing.isAuthor = true;
  }

  const handleReport = async (id, reportCount, reports) => {
    const newReportCount = reportCount + 1;

    if (reports.includes(storage.user.uid)) {
      toast.error("Thank you! We're reviewing your report!", {
        duration: 2000,
        position: "bottom-center",
      });
    } else {
      try {
        await firestore
          .collection("five_things")
          .doc(`${id}`)
          .update({
            reports: firebase.firestore.FieldValue.arrayUnion(storage.user.uid),
            reportCount: newReportCount,
          });
      } catch (e) {
        console.log(e);
      } finally {
        toast.success("Reported!", {
          duration: 2000,
          position: "bottom-center",
        });
      }
    }
    return true;
  };

  const handleDelete = async (id) => {
    // Show modal/alert?
    try {
      await firestore.collection("five_things").doc(`${id}`).update({
        hidden: true,
      });
    } catch (e) {
      console.log(e);
    } finally {
      // might want to launch some success thing on success...
      console.log("DELETING SUCCESS!");
    }
    return true;
  };

  return (
    <div className={ isLiked ? "PostDataWrapperLiked" : "PostDataWrapper" }>
      <div className="smallText">
        {`${
          thing.createdAt && dateParser(thing.createdAt.toDate().toDateString())
        }`}
      </div>
      <div className="PostData">
        {storage.user && !storage.user.isAnonymous ? (
          thing.isAuthor ? (
            <button onClick={() => handleDelete(thing.id)}>Delete</button>
          ) : (
            <div>
              {" "}
              <button
                onClick={() =>
                  handleReport(thing.id, thing.reportCount, thing.reports)
                }
              >
                Flag this post?
              </button>
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default view(PostData);
