import React from "react";
import { view } from "@risingstack/react-easy-state";
import "./../styles/index.css";
import "./../styles/app.css";

const Footer = () => {
return(
<div className="Footer">
  <h5 style={{marginBottom:"8px"}}>Five Things That Are Good was born in September, 2010.</h5>
  <div className="smallText">
    Made for you with &hearts;&nbsp;by&nbsp;
    <a href="mailto:hello@abzugly.com">Johanna</a> & Nikita
  </div>
</div>)
};

export default view(Footer);
