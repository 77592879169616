import firebase from "firebase/app";
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCibfyqhtaVgOG3lDRVgrBqe2yNBxXWSaI",
  authDomain: "things-that-are-good.firebaseapp.com",
  databaseURL: "https://things-that-are-good-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "things-that-are-good",
  storageBucket: "things-that-are-good.appspot.com",
  messagingSenderId: "524814926476",
  appId: "1:524814926476:web:09d07f66f63556705bb1a9",
  measurementId: "G-JB96GD3CD2"
};

firebase.initializeApp(firebaseConfig);

export default firebase.firestore();
