import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { view } from "@risingstack/react-easy-state";
import { storage } from "react-easy-params";
import { useParams, useHistory } from "react-router-dom";
import ThingsContainer from "./../components/ThingsContainer.js";
import FullHeight from "react-full-height";
import Header from "./../components/Header.js";
import "./../styles/output.css";
import "./../styles/index.css";
import "./../styles/app.css";

const PersonView = (props) => {
  let { id } = useParams();
  let history = useHistory();

  if (!id) {
    // if params == `me` then render me.
    if (storage.user.uid) {
      id = storage.user.uid;
    } else {
      history.push(`/Output`);
      id = null;
    }
  }

  const [value, loading, error] = useCollection(
    firebase
      .firestore()
      .collection("five_things")
      .where("user.id", "==", id)
      .orderBy("createdAt", "desc"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );



  return (
    <>
      <Header
        title={`Five Things That Are Good`}
        description={`Read all the things by person. See other peoples' perspectives on positivity. Then, add your own.`}
      />
      <FullHeight>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <div className="PageWrapper"><h1>Loading Things...</h1></div>}
        {value && (
          <div className="PageWrapper">
            <h1>
              {value.docs.length > 0
                ? `${value.docs.length * 5} things by ${
                    value.docs[0].data().user.name
                  }`
                : `Nothing here yet!`}
            </h1>
            {value.docs.map((doc) => (
              <React.Fragment key={doc.id}>
                <ThingsContainer thingSet={doc.data()} thingSetId={doc.id} />
              </React.Fragment>
            ))}
          </div>
        )}
      </FullHeight>
    </>
  );
};

export default view(PersonView);
