import React from 'react';
import { view } from '@risingstack/react-easy-state';
import { useDocumentDataOnce} from "react-firebase-hooks/firestore";
 import firebase from "firebase/app";
 import { useParams } from "react-router-dom";
 import "./../styles/app.css";
 import FullHeight from "react-full-height";
 import "./../styles/index.css";
 import "./../styles/output.css";
 import ThingsInfo from "./../components/ThingsInfo.js";
 import PostData from "./../components/PostData.js";
 import Header from "./../components/Header.js";



import FormFields from "./../components/FormFields.js";

const ThingSetView = () => {
  let { id } = useParams();
  const ref = firebase.firestore().doc(`five_things/${id}`);

  const [value, loading, error] = useDocumentDataOnce(ref, {});

  let data = value;

  if (data) {
    return (

      <FullHeight className="PageWrapper">
      <Header
        title={`Five Things That Are Good`}
        description={`Check out these five good things.`}
      />
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <div className="PageWrapper"><h1>Loading Things...</h1></div>}
      {value && (
        <>
        <h1>
        {`Five things that are good`}
        </h1>
        <div className="ThingsCard">
        <div className="ThingsContainer">
        <div className="FormFields">
        <FormFields thing={data} thingSetId={data.id} />
        </div>
        <PostData thing={data} />
        <ThingsInfo thing={data} thingSetId={data.thingSetId} />
          </div>
        </div>
        </>
  )}
      </FullHeight>
    )
  } else {
    return(<div>Oops!</div>)
  }

}

export default view(ThingSetView);
