import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { view } from "@risingstack/react-easy-state";
import { ThemeContext } from "./ThemeContext.js";
import { Toaster } from "react-hot-toast";
import { storage } from "react-easy-params";
import Nav from "./components/Nav.js";
import Header from "./components/Header.js";

import Input from "./screens/input";
import Output from "./screens/output";
import PersonView from "./screens/person";
import FavoritesView from "./screens/favorites";
import About from "./screens/about";
import ThingSetView from "./screens/ThingSetView.js";


import "./styles/app.css";

function App() {
  const [theme, setTheme] = useState(storage.theme ? storage.theme : "bright");

  if (!storage.user) {
    storage.user = {
      isAuthenticated: false,
      isAnonymous: true,
      name: "anonymous",
      id: null
    };
  }

  return (
<>
    <Header desc="A place to come to whether you're happy or sad. A positivity piggy bank to contribute to or browse however you like. Use and browse anonymously. Sign up for FREE for extra features." />

        <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className="App" data-theme={theme}>
        <Router>
          <Route path="/:page" component={Nav} />
          <Route exact path="/" component={Nav} />

          <Route exact path="/" component={Input} />
          <Route path="/input" component={Input} />
          <Route path="/output">
            <Output/>
          </Route>

          <Route path="/about" component={About} />
          <Route exact path="/user">
            <PersonView origin={"me"} />
          </Route>
          <Route
            exact
            path="/favorites/"
            render={() => <FavoritesView setTheme={{ useState }} />}
          />
          <Route exact path="/user/:id">
            <PersonView origin={"others"} />
          </Route>
          <Route exact path="/thingsets/:id" component={ThingSetView} />
        </Router>
        <Toaster />
      </div>
    </ThemeContext.Provider>
    </>
  );
}

export default view(App);
