import React, { useState, useContext } from "react";
import { view } from "@risingstack/react-easy-state";
import { useLocation, withRouter, NavLink } from "react-router-dom";
import { storage } from "react-easy-params";
import AuthContainer from "./auth/AuthContainer.js";
import "./../styles/nav.css";
import "./../styles/index.css";
import Greeting from "./auth/Greeting.js";
import { slide as Menu } from "react-burger-menu";
import { useMediaQuery } from "react-responsive";
import { Icon } from "@iconify/react";
import ThemeToggler from "./ThemeToggler.js";

// make a new context
const MyContext = React.createContext();

// create the provider
const MyProvider = (props) => {
  const [menuOpenState, setMenuOpenState] = useState(false);

  return (
    <MyContext.Provider
      value={{
        isMenuOpen: menuOpenState,
        toggleMenu: () => {
          setMenuOpenState(!menuOpenState);
          menuOpenState ? document.body.style.overflow = null : document.body.style.overflow = "hidden"
        },
        stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};

const HamburgerTrigger = (props) => {
  const ctx = useContext(MyContext);
  return (
    <div
      className={ctx.isMenuOpen ? "TopNavBarMenuOpen" : "TopNavBarBackground"}
    >
      <div id="NavBarGreeting">
      <Greeting />
      </div>
      <div className="HamburgerIcons">

        <button onClick={ctx.toggleMenu}>
          {ctx.isMenuOpen ? (
            <Icon
              icon="akar-icons:cross"
              alt="close menu"
              width="32"
              id="closeicon"
            />
          ) : (
            <Icon
              icon="akar-icons:two-line-horizontal"
              alt="open menu"
              width="40"
              id="openicon"
            />
          )}
        </button>
      </div>
    </div>
  );
};

const NavLinksContainer = view(
  withRouter((props) => {
    const ctx = useContext(MyContext);
    const isAnonymous = storage.user && storage.user.isAnonymous !== false;
    return isAnonymous ? (
      <>
        <NavLink
          to={`/`}
          exact
          title="Add more"
          className="NavLink"
          activeClassName="NavlinkActive"
        >
          <span onClick={ctx.toggleMenu}>Add more</span>
        </NavLink>
        <NavLink
          to={`/Output`}
          exact
          title="View all"
          className="NavLink"
          activeClassName="NavlinkActive"
        >
          <span onClick={ctx.toggleMenu}>View all</span>
        </NavLink>
        <NavLink
          to="/about"
          title="About"
          exact
          className="NavLink"
          activeClassName="NavlinkActive"
        >
          <span onClick={ctx.toggleMenu}>About</span>
        </NavLink>
      </>
    ) : (
      <>
        <NavLink
          to={`/`}
          exact
          title="Add more"
          className="NavLink"
          activeClassName="NavlinkActive"
        >
          <span onClick={ctx.toggleMenu}>Add more</span>
        </NavLink>
        <NavLink
          to={`/Output`}
          exact
          title="View all"
          className="NavLink"
          activeClassName="NavlinkActive"
        >
          <span onClick={ctx.toggleMenu}>View all</span>
        </NavLink>
        <NavLink
          to="/user"
          title="My Things"
          exact
          className="NavLink"
          activeClassName="NavlinkActive"
        >
          <span onClick={ctx.toggleMenu}>My Things</span>
        </NavLink>
        <NavLink
          to="/favorites"
          title="Favorites"
          exact
          className="NavLink"
          activeClassName="NavlinkActive"
        >
          <span onClick={ctx.toggleMenu}>Favorites</span>
        </NavLink>
        <NavLink
          to="/about"
          title="About"
          exact
          className="NavLink"
          activeClassName="NavlinkActive"
        >
          <span onClick={ctx.toggleMenu}>About</span>
        </NavLink>
      </>
    );
  })
);

const Default = ({ children }) => {
  return (
    <div className="DefaultNav">
      <div className="NavLinks">
        <Greeting />
        <NavLinksContainer />
        <ThemeToggler />

      </div>
      <div className="NavBottom">
        <AuthContainer />
      </div>
    </div>
  );
};

const Mobile = ({ children }) => {
  const ctx = useContext(MyContext);
  return (
    <Menu right noOverlay noTransition disableAutoFocus isOpen={ctx.isMenuOpen}>
      <div className="MobileNav">
        <div className="NavLinksMobile">
          <NavLinksContainer />
          <ThemeToggler />

        </div>
        <div className="NavBottomMobile">

          <AuthContainer />
        </div>
      </div>
    </Menu>
  );
};

const Nav = () => {
  const isMobile = useMediaQuery({ maxWidth: 700 });
  const location = useLocation();

  let title = "My Page";

  switch (location.pathname) {
    case "/Output":
      title = "Five Things That Are Good";
      break;
    case "/Input":
      title = "Five Things That Are Good";
      break;
    case "/user":
      title = `Five Things That Are Good`;
      break;
    case "/About":
      title = `Five Things That Are Good`;
      break;
    case "/favorites":
      title = `Five Things That Are Good`;
      break;
    default:
      title = "Five Things That Are Good";
      break;
  }

  return (
    <MyProvider>
      {isMobile ? (
        <div className="TopNavBar">
          <HamburgerTrigger title={title} />
          <Mobile />
        </div>
      ) : (
        <Default />
      )}
    </MyProvider>
  );
};

export default view(Nav);
