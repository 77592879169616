import React from "react";
import { view } from "@risingstack/react-easy-state";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import ThingsContainer from "./../components/ThingsContainer.js";
import FullHeight from "react-full-height";
import Header from "./../components/Header.js";
import "./../styles/output.css";
import "./../styles/index.css";
import "./../styles/app.css";

const Output = () => {
  const [value, loading, error] = useCollection(
    firebase
      .firestore()
      .collection("five_things")
      .where("hidden", "==", false)
      .where("reportCount", "<=", 4)
      .orderBy("reportCount", "asc")
      .orderBy("createdAt", "desc"),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );

  return (
  <>
  <Header title={'View All The Things - Five Things That Are Good'} desc={'All posts in chronological order. See what other people are smiling about, and maybe you will smile too.'}/>
<FullHeight>
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <div className="PageWrapper"><h1>Loading Things...</h1></div>}
        {value && (
          <div className="PageWrapper">
            <h1>There are {`${value.docs.length * 5} things that are good`}</h1>
            {value.docs.map(doc => (
              <React.Fragment key={doc.id}>
                <ThingsContainer isBright={false} thingSet={doc.data()} thingSetId={doc.id} />
              </React.Fragment>
            ))}
          </div>
        )}
    </FullHeight>
    </>
  );
};

export default view(Output);
