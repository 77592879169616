import React from "react";
import { storage } from "react-easy-params";
import { view } from "@risingstack/react-easy-state";
import { useMediaQuery } from "react-responsive";

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};


const LoggedInGreeting = () => {

  const name = storage.user ? storage.user.name : 'guest'

  return (
    <>
    <Mobile>
        <div className="greetingMobile">
          Hello, <span className="greetingHighlight">{name}</span>!
        </div>
    </Mobile>
    <Default>
      <div className="greeting">
        Hello, <a href="/user" className="greetingHighlight">{name}</a>, my old friend.
      </div>
      </Default>
      </>
    )
  };



const AnonymousGreeting = () => {

  return (
    <>
    <Default>
      <div className="greeting">
        You're posting <span className="greetingHighlight">anonymously</span>.
      </div>
      </Default>

      <Mobile>
      <div>
        You're posting <span className="greetingHighlight">anonymously</span>.
      </div>
    </Mobile>
    </>
  );
};

const Greeting = () => {
  const isAnonymous = storage.user && storage.user.isAnonymous !== false
  return isAnonymous ? <AnonymousGreeting /> : <LoggedInGreeting />;
};

export default view(Greeting);
