import React from "react";
import { view } from "@risingstack/react-easy-state";


const FormFields = (data) => {
  const thing = data.thing;

  return (
    <>
      <div>{thing.thing1}</div>
      <div>{thing.thing2}</div>
      <div>{thing.thing3}</div>
      <div>{thing.thing4}</div>
      <div>{thing.thing5}</div>
    </>
  )
};

export default view(FormFields);
