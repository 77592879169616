import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { view } from "@risingstack/react-easy-state";
import { storage } from "react-easy-params";
import { useHistory } from "react-router-dom";
import ThingsContainer from "./../components/ThingsContainer.js";
import Header from "./../components/Header.js";

import FullHeight from "react-full-height";
import "./../styles/app.css";
import "./../styles/index.css";

const FavoritesView = (props) => {
  props.setTheme.useState('bright');
  let history = useHistory();
  let id = null;

  if (storage.user.uid) {
    id = storage.user.uid
  } else {
    history.push(`/Output`);
  }

  const [value, loading, error] = useCollection(
    firebase
      .firestore()
      .collection("five_things")
      .where("likedBy", "array-contains", id)
      .orderBy("createdAt", "desc"),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );


  return (
    <>
    <Header title={'My Favorite Things - Five Things That Are Good'} desc={'Every set of things that you like will show up here. Come back to it whenever you need for an instant mood boost.'}/>
    <FullHeight>
      {error && <strong>Error: {JSON.stringify(error)}</strong>}
      {loading && <div className="PageWrapper"><h1>Loading Things...</h1></div>}
      {value && (
        <div className="PageWrapper">
        <h1>You've saved {`${value.docs.length * 5} things that are good`}</h1>
          {value.docs.map(doc => (
            <React.Fragment key={doc.id}>
              <ThingsContainer isBright={true} thingSet={doc.data()} thingSetId={doc.id} />
            </React.Fragment>
          ))}
        </div>
      )}
    </FullHeight>
    </>
  );
};


export default view(FavoritesView);
