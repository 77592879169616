import React from "react";
import { storage } from "react-easy-params";
import { view } from "@risingstack/react-easy-state";
import { Icon } from "@iconify/react";
import "./../styles/app.css";
import "./../styles/index.css";
import Spacer from "react-spacer";
import Kofi from "./../components/Kofi.js";
import AuthContainer from "./auth/AuthContainer.js";


const FullCTA = () => {
  const isAnonymous = storage.user && storage.user.isAnonymous !== false;
  return isAnonymous ? (
    <div className="fullCTA">
      <Icon icon="akar-icons:face-very-happy" className="ctaIcon" />
      <h3>Unlock all the features.</h3>
      Log in or sign up now for FREE.
      <Spacer height="16px"/>
        <AuthContainer/>
    </div>
  ) : (
    <div className="fullCTA">
      <div id="KofiCTABig">
        <Icon icon="akar-icons:face-very-happy" className="ctaIcon" />
        <h3>Have we boosted your mood a little?</h3>

        <Kofi id="fivethings" />
      </div>

    </div>

  );
};

export default view(FullCTA);
