import React from "react";
import firestore from "./../utils/firebase";
import firebase from "firebase/app";
import "firebase/auth";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import { storage } from "react-easy-params";
import { view } from "@risingstack/react-easy-state";
import Header from "./../components/Header.js";
import toast from "react-hot-toast";
import appStore from "./../utils/appStore";
import Spacer from "react-spacer";
import FullHeight from "react-full-height";
import "./../styles/input.css";
import "./../styles/index.css";
import "./../styles/app.css";

const validationRule = {
  maxLength: {
    value: 200,
    message: "Try again using fewer characters. This is just too many. :) ",
  },
  required: {
    value: true,
    message: "Five (5) things that are good. You need to add more.",
  },
  minLength: {
    value: 3,
    message: "Could you elaborate? (Minimum 3 characters per thing.)",
  },
};

const Input = (props) => {

  const submitForm = async (data) => {
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    data.createdAt = timestamp;
    data.likes = 0;
    data.likedBy = [];
    data.reports = [];
    data.reportCount = 0;
    data.hidden = false;

    try {
      await firestore.collection("five_things").add(data);
    } catch (e) {
      toast.error("Oops...");
    } finally {
      return true;
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  let history = useHistory();

  const onSubmit = async (data) => {
    const isChecked = data.postAnonymously;
    if (!storage.user.isAnonymous && storage.user.isAuthenticated) {
      data.user = !isChecked
        ? {
            id: storage.user.uid,
            name: storage.user.name,
            isAnonymous: false,
          }
        : { id: storage.user.uid, name: "anonymous", isAnonymous: true };
    } else if (storage.user.isAnonymous && storage.user.isAuthenticated) {
      data.user = {
        //anonymous and authenticated
        id: storage.user.uid,
        name: "anonymous",
        isAnonymous: true,
      };
    } else {
      const user = await appStore.anonymousAuth();
      data.user = {
        //brand new
        id: user.uid,
        name: "anonymous",
        isAnonymous: true,
      };
    }
    await submitForm(data);
    reset();
    history.push("/Output");
  };

  var isTouch =
    !!("ontouchstart" in window) || window.navigator.msMaxTouchPoints > 0;

  return (
    <>
      <Header />
      <FullHeight className="InputForm">
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <h1>{`Five things that are good`}</h1>

          <div className="FormElementWrapper">
            <input
              className="FormElement"
              data-lpignore="true"
              {...register("thing1", validationRule)}
              placeholder="Start with 1 thing..."
            />
            <input
              className="FormElement"
              data-lpignore="true"
              {...register("thing2", validationRule)}
            />
            <input
              className="FormElement"
              data-lpignore="true"
              {...register("thing3", validationRule)}
            />
            <input
              className="FormElement"
              data-lpignore="true"
              {...register("thing4", validationRule)}
            />
            <input
              className="FormElement"
              data-lpignore="true"
              {...register("thing5", validationRule)}
            />
            <div
              className={
                storage.user.isAnonymous
                  ? "inputCheckboxHidden"
                  : "inputCheckbox"
              }
            >
              <label>
                <input
                  type="checkbox"
                  {...register("postAnonymously")}
                  id="postAnonymously"
                />
                <span></span>
                <span style={{ fontSize: "12px" }}>Post anonymously</span>
              </label>
            </div>
          </div>

          <Spacer height="16px" />
          <div className={isTouch ? "MobileTextCTA" : "TextCTA"}>
            <input
              className={isTouch ? "MobileTextCTA" : "TextCTA"}
              type="submit"
              value="Tell the world &#10142;"
            />
          </div>

          <div>
            {Object.keys(errors)
              .slice(0, 1)
              .map((fieldName) => {
                return (
                  <ErrorMessage
                    errors={errors}
                    name={fieldName}
                    key={fieldName}
                    render={({ message }) => (
                      <span className="ErrorText">{message}</span>
                    )}
                  />
                );
              })}
          </div>
        </form>
      </FullHeight>
    </>
  );
};

export default view(Input);
