import React from "react";
import { view } from "@risingstack/react-easy-state";
import "./../styles/about.css";
import "./../styles/index.css";
import "./../styles/app.css";
import { Icon } from "@iconify/react";
import Header from "./../components/Header.js";
import FullCTA from "./../components/CTA.js";
import FullHeight from "react-full-height";
import Footer from "./../components/Footer.js";
import Spacer from "react-spacer";
const About = () => {
  return (
    <>
    <Header title={'About - Five Things That Are Good'} description={'Learn more about Five Things That Are Good, including the benefits of signing up for a FREE account.'}/>
    <FullHeight>
    <div className="PageWrapper">
      <h1>Take a moment to appreciate the good things.</h1>
      <div className="paraPadding">
        <p>
          Five Things That Are Good is a place to come to – whether you're happy
          or sad – and appreciate the little things. It is a positivity "piggy bank"
          to contribute to or browse however you like.
        </p>
        We created this with good intentions and rely on our visitors to respond
        with the same. There are only a few (common sense) rules.
        <ul id="rules">
          <li>
            <b>Let’s keep it PG-13.</b> Content involving graphic sexual
            descriptions, racism, drugs, cursing, violence, etc. will not be
            tolerated.
          </li>
          <li>
            <b>No filler or gibberish posts.</b> We want this place to be filled
            with uplifting and unique perspectives, not the aftermath of
            keyboard mashings.{" "}
          </li>
          <li>
            <b>No other spam.</b> No selling, promotion, or anything else fishy.
            Any post is susceptible to deletion at the discretion of our
            moderators.{" "}
          </li>
        </ul>
      </div>

      <FullCTA />
      <div className="paraPadding"><Spacer height="24px"/>
      <h2>Post anonymously, or get a <span className="rainbowHighlight">free</span> account to access these features</h2>
      <Spacer height="24px"/></div>
      <table id="featuresTable">
        <tr>
          <th style={{ width: "50%" }}>Features</th>
          <th className="featuresLabel">Anonymous</th>
          <th className="featuresLabel">FREE account</th>
        </tr>
        <tr>
          <td>Post anonymously</td>
          <td className="alignCenter">
            <Icon
              icon="akar-icons:circle-check"
              inline={true}
              className="tableIcon"
            />
          </td>
          <td className="alignCenter">
            <Icon
              icon="akar-icons:circle-check"
              inline={true}
              className="tableIcon"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3" className="featureDivider" />
        </tr>
        <tr>
          <td>Post as you!</td>
          <td />
          <td className="alignCenter">
            <Icon
              icon="akar-icons:circle-check"
              inline={true}
              className="tableIcon"
            />
          </td>
        </tr>
        <td colspan="3" className="featureDivider" />
        <tr>
          <td>Share posts or profiles of interest.</td>
          <td className="alignCenter">
            <Icon
              icon="akar-icons:circle-check"
              inline={true}
              className="tableIcon"
            />
          </td>
          <td className="alignCenter">
            <Icon
              icon="akar-icons:circle-check"
              inline={true}
              className="tableIcon"
            />
          </td>
        </tr>
        <td colspan="3" className="featureDivider" />
        <tr>
          <td>Keep track of all your posts on your very own profile page</td>
          <td />
          <td className="alignCenter">
            <Icon
              icon="akar-icons:circle-check"
              inline={true}
              className="tableIcon"
            />
          </td>
        </tr>
        <td colspan="3" className="featureDivider" />
        <tr>
          <td>Favorite posts, and access them all on one page</td>
          <td />
          <td className="alignCenter">
            <Icon
              icon="akar-icons:circle-check"
              inline={true}
              className="tableIcon"
            />
          </td>
        </tr>
        <td colspan="3" className="featureDivider" />
        <tr>
          <td>Switch between light, dark, and bright themes.</td>
          <td />
          <td className="alignCenter">
            <Icon
              icon="akar-icons:circle-check"
              inline={true}
              className="tableIcon"
            />
          </td>
        </tr>
        <td colspan="3" className="featureDivider" />
        <tr>
          <td>Flag offensive posts for deletion</td>
          <td />
          <td className="alignCenter">
            <Icon
              icon="akar-icons:circle-check"
              inline={true}
              className="tableIcon"
            />
          </td>
        </tr>
        <td colspan="3" className="featureDivider" />
        <tr>
          <td>Delete any of your posts</td>
          <td />
          <td className="alignCenter">
            <Icon
              icon="akar-icons:circle-check"
              inline={true}
              className="tableIcon"
            />
          </td>
        </tr>
      </table>

      <div id="contact">

        <Icon icon="akar-icons:chat-question" width="40" height="40" />
        <h3>
          Feedback? Questions?<br /> Just want to say "Hi"?
        </h3>
        <div>
          We'd love to hear from you.&nbsp;
          <a href="mailto:fivethingsthataregood@gmail.com"> Email us.</a>
        </div>
      </div>

    </div>
<Footer/>
    </FullHeight>
    </>
  );
};

export default view(About);
